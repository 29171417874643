<template>
    <div>
        <section class="hero is-medium">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                        <h1 class="is-size-3"><i class="fab fa-deezer mr-2"></i>Tetris</h1>
                            <form action="" class="box">
                                <div class="field">
                                    <label for="" class="label">Username</label>
                                    <div class="control has-icons-left">
                                        <input type="email"
                                               v-model="user.username" placeholder="e.g. EPS\username" class="input" required>
                                        <span class="icon is-small is-left">
                                            <i class="fa fa-envelope"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="" class="label">Password</label>
                                    <div class="control has-icons-left">
                                        <input type="password"
                                        placeholder="*******"
                                                     v-model="user.password" class="input" required>
                                        <span class="icon is-small is-left">
                                            <i class="fa fa-lock"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="field">
                                    <button class="button is-success"
                                        @click.prevent="$parent.doLogin(user)">
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default{
        data(){
            return {
                user: {}
            }
        },
        methods:{

        },
        created(){

        }
    }
</script>

<style scoped>

</style>

